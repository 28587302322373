import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { PAGEURL } from '../../constants'
import { currencyN } from '../utils'
import { PriceTile } from '../theme/plans/Plan'

import styles from '../marketplace/Marketplace.module.scss'

const calcDiscount = (price, coupon_data) => {
  if (coupon_data.percent_off) {
    return price * (1 - 0.01 * coupon_data.percent_off)
  } else if (coupon_data.amount_off) {
    return Math.max(0, price - parseFloat(coupon_data.amount_off))
  }
  return null
}

const FormatPrice = ({
  commitment,
  price,
  formattedPrice,
  interval,
  coupon_data
}) => {
  if (coupon_data) {
    if (!price) {
      return (
        <div className="price">
          {formattedPrice}
          <br />
          <small>&nbsp;</small>
          {commitment}
        </div>
      )
    }
    const discount = calcDiscount(price, coupon_data)
    if (discount) {
      return (
        <div className="price">
          {currencyN(discount)}/{interval}
          <br />
          <small>
            <s>{formattedPrice}</s>
          </small>
          {commitment}
        </div>
      )
    }
  }
  return (
    <div className="price">
      {formattedPrice}
      {commitment}
    </div>
  )
}

FormatPrice.propTypes = {
  commitment: PropTypes.object,
  price: PropTypes.number,
  formattedPrice: PropTypes.string,
  interval: PropTypes.string,
  coupon_data: PropTypes.object
}

const _Tier = inject('store')(
  observer(
    ({
      store,
      title,
      tag,
      monthlyPrice,
      yearlyPrice,
      plan,
      plan_id,
      frequency,
      commitment_monthly,
      commitment_yearly,
      description,
      hideAction,
      showFeatures,
      features
    }) => {
      const { isLoggedIn } = store.sessionData
      const { organization_data } = store
      let url = null
      let urlText = ''
      let price = ''
      if (plan.prices) {
        const p = plan.prices[0]
        if (p) {
          price = p.stripe_price_id
        }
      }
      if (isLoggedIn) {
        url = plan.cta_url || `${PAGEURL.SUBSCRIBE}?sopt=${price}`
        urlText = plan.cta_text || 'Upgrade'
      } else {
        url =
          plan.cta_url ||
          store.siteData.get('signup_url') ||
          `${PAGEURL.SIGNUP}?sopt=${price}`
        urlText = plan.cta_text || 'Join'
      }
      // Check if the user already has access to this plan, if they do
      // hide the action
      let ha = hideAction
      const userPlans = organization_data?.all_plan_ids || []
      if (userPlans && !ha) {
        ha = userPlans.includes(plan_id)
      }

      let commitment = <div className={styles.empty_commitment}>&nbsp;</div>
      if (commitment_monthly || commitment_yearly) {
        commitment = (
          <div>
            {frequency === 'monthly' ? commitment_monthly : commitment_yearly}{' '}
            commitment
          </div>
        )
      }
      const extras = {}
      if (monthlyPrice) {
        extras.monthlyPrice = <>{monthlyPrice}/month</>
      }
      if (yearlyPrice) {
        extras.yearlyPrice = <>{yearlyPrice}/year</>
      }
      return (
        <PriceTile
          title={title}
          tag={tag}
          url={url}
          urlText={urlText}
          frequency={frequency}
          description={description}
          hideAction={ha}
          pricingModel={plan?.pricing_model}
          trialDuration={plan?.trial_duration}
          showFeatures={showFeatures}
          features={features}
          commitment={commitment}
          {...extras}
        />
      )
    }
  )
)

_Tier.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  monthly: PropTypes.number,
  monthlyPrice: PropTypes.string,
  yearly: PropTypes.number,
  yearlyPrice: PropTypes.string,
  frequency: PropTypes.string,
  description: PropTypes.string,
  show_features: PropTypes.bool,
  show_description: PropTypes.bool,
  hideAction: PropTypes.bool,
  hideDescription: PropTypes.bool,
  plan: PropTypes.object,
  plan_id: PropTypes.number,
  store: PropTypes.object,
  organization_name: PropTypes.string
}

const Tier = _Tier

export default Tier
