import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { IoMdCheckmark, IoIosInformationCircleOutline } from 'react-icons/io'

import styled from 'styled-components'

import { ExploreButton } from '../default/common'
import { Modal } from '/components/admin/Modal'
import useMarketo from '/components/marketplace/enroll-request/Marketo'

export const PricingTable = ({ children }) => (
  <PricingTableWrapper>{children}</PricingTableWrapper>
)

export const PriceTile = inject('store')(
  observer(
    ({
      store,
      title,
      tag,
      className,
      frequency = 'monthly',
      monthlyPrice,
      yearlyPrice,
      description,
      url,
      urlText,
      showFeatures,
      features,
      hideAction = false,
      pricingModel,
      trialDuration = 0,
      commitment
    }) => {
      const [showModal, setShowModal] = useState(false)
      let trialContent = null
      if (trialDuration) {
        trialContent = `${trialDuration} day free trial`
      }
      let price = null
      if (pricingModel === 'free') {
        price = <PlanPrice>Free Plan</PlanPrice>
      } else if (pricingModel === 'manual') {
        price = <PlanPrice>&nbsp;</PlanPrice>
      } else if (frequency === 'monthly' && monthlyPrice) {
        price = (
          <PlanPrice>
            {monthlyPrice}
            <PriceDiv>
              <Strong>{commitment}</Strong>
            </PriceDiv>
            <PlanDeal>{trialContent}</PlanDeal>
          </PlanPrice>
        )
      } else {
        price = (
          <PlanPrice>
            {yearlyPrice}
            {yearlyPrice && (
              <PriceDiv>
                <Strong>save over monthly</Strong>
              </PriceDiv>
            )}
            {trialContent && <PlanDeal>{trialContent}</PlanDeal>}
          </PlanPrice>
        )
      }
      let featuresContent = null
      if (showFeatures) {
        featuresContent = (
          <Features
            dangerouslySetInnerHTML={{
              __html: features
            }}
          />
        )
      }
      let actionContent = <ActionRow />
      if (!hideAction) {
        // TODO: refactor this out. integration the marketo plugin with this
        if (store.siteData.get('slug') === 'cpassolutions') {
          actionContent = (
            <ActionRow>
              <ExploreButton href="#" onClick={() => window.showMarketoModal()}>
                {urlText}
              </ExploreButton>
            </ActionRow>
          )
        } else {
          actionContent = (
            <ActionRow>
              <ExploreButton href={url}>{urlText}</ExploreButton>
            </ActionRow>
          )
        }
      }
      return (
        <Plan className={className}>
          <PlanTop>
            <PlanBox>
              <PlanTopHeader>
                <H2>{title}</H2>
                <H3>&nbsp;{tag}&nbsp;</H3>
              </PlanTopHeader>
              <PlanContent>
                {price}
                <PlanDescription
                  dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
                {featuresContent}
                {actionContent}
              </PlanContent>
            </PlanBox>
          </PlanTop>
        </Plan>
      )
    }
  )
)

export const Checkmark = ({ value }) => {
  if (!value) {
    return <Nope></Nope>
  } else if (typeof value === 'string') {
    return value
  } else {
    return (
      <Yup>
        <IoMdCheckmark />
      </Yup>
    )
  }
}

export const PricingSection = ({ section, plans }) => {
  return (
    <>
      <tr>
        <PgHeader colSpan={plans.length + 1}>{section.name}</PgHeader>
      </tr>
      {(section.products || []).map((r, i) => (
        <PricingRow row={r} key={i} plans={plans} />
      ))}
    </>
  )
}

export const PricingRow = ({ row, plans }) => {
  return (
    <tr>
      <PgTitleCell>
        {row.product_name}
        <div>{row.deal}</div>
        {false && row.tooltip && (
          <span>
            <IoIosInformationCircleOutline />
          </span>
        )}
      </PgTitleCell>
      {plans.map((plan, i) => {
        return (
          <PgCellWrapper key={i}>
            <Checkmark
              value={
                row.plans.filter((p) => p.id.toString() === plan.id.toString())
                  .length > 0
              }
            />
          </PgCellWrapper>
        )
      })}
    </tr>
  )
}

export const PricingHeader = ({
  title,
  plan,
  frequency,
  urlCallback,
  url,
  urlText
}) => {
  const href = url
  if (!href && urlCallback) {
    url = urlCallback(plan, frequency)
  }
  return (
    <PgCellWrapper>
      <div>
        <PgCellH2>{title}</PgCellH2>
        <div>
          {urlText && <ExploreButton href={href}>{urlText}</ExploreButton>}
        </div>
      </div>
    </PgCellWrapper>
  )
}

export const PricingGrid = ({ plans, sections, urlText, urlCallback }) => {
  return (
    <>
      <StickyPricingGrid>
        <thead>
          <PgTierRow>
            <td></td>
            {plans.map((plan) => (
              <PricingHeader
                key={plan.id}
                title={plan.title}
                barClass={plan.barClass}
                plan={plan}
                urlCallback={urlCallback}
                urlText={urlText}
                frequency={plan.frequency}
              />
            ))}
          </PgTierRow>
        </thead>
      </StickyPricingGrid>
      <PricingGridTable>
        <tbody>
          {sections.map((section, i) => (
            <PricingSection
              key={i}
              plans={plans}
              section={section}
              urlText={urlText}
              urlCallback={urlCallback}
            />
          ))}
        </tbody>
      </PricingGridTable>
    </>
  )
}

const PricingTableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0;
  justify-content: center;

  & > div {
    max-width: 350px;

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.medium}) {
      max-width: 100%;
    }
  }
`

const PlanPrice = styled.div`
  font-size: ${(props) => props.theme.fontSizes.medium};
  text-align: center;
  flex: 0 1 auto;
  padding: 20px 0;
  font-weight: 600;
  min-height: 120px;

  span {
    font-size: ${(props) => props.theme.fontSizes.large};
  }
`

const PriceDiv = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
`

const Strong = styled.strong`
  display: block;
  margin: 0 0 5px 0;
`

const PlanDeal = styled.div`
  margin: 20px 0;
  font-weight: 600;
`

const Features = styled.div`
  display: none;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    display: block;
  }
`

const ActionRow = styled.div`
  flex: 0 1 auto;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    flex-direction: column;
  }
`

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 10px 0 10px;
  min-width: 300px;

  ${(props) => props.theme.media.phone`
    width: 100%;
  `}
`

const PlanTop = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  margin: 0 0 20px 0;
  border-radius: 10px;
`

const PlanBox = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
`

const PlanTopHeader = styled.div`
  background-color: ${(props) =>
    props.theme.colors.secondary || props.theme.colors.darkBlue};
  border-radius: 10px 10px 0 0;
  padding: 20px 0;
`

const H2 = styled.h2`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.large} !important;
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  margin: 0 !important;
  font-weight: 600 !important;
  padding: 20px 0 10px 0 !important;

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.large}) {
    font-size: 2rem !important;
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
    font-size: ${(props) => props.theme.fontSizes.large} !important;
  }
`

const H3 = styled.h3`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  margin: 0 !important;
  font-weight: 400 !important;
  padding: 0 0 20px 0 !important;
`

const PlanContent = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`

const PlanDescription = styled.div`
  flex: 1 1 auto;
  font-size: ${(props) => props.theme.fontSizes.normal};
  text-align: center;
`

const Nope = styled.div`
  height: 25px;
  width: 25px;
  margin: 0 auto;
  color: ${(props) => props.theme.colors.grey};

  svg {
    height: 100%;
    width: 100%;
  }
`

const Yup = styled(Nope)`
  color: #324b75;
`

const PgHeader = styled.td`
  font-size: ${(props) => props.theme.fontSizes.medium};
  background-color: ${(props) => props.theme.colors.superLightGrey};
  padding: 10px 10px;
`

const PgTitleCell = styled.td`
  div {
    font-size: ${(props) => props.theme.fontSizes.xsmall};
    padding: 2px 0 0 10px;
    font-style: italic;
  }

  span {
    float: right;
    color: ${(props) => props.theme.colors.grey};

    svg {
      height: 20px;
      width: 20px;
    }
  }
`

const PgCellWrapper = styled.td`
  width: 200px !important;
  text-align: center;

  /*
  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  */
`

const PgCellH2 = styled.h2`
  margin: 20px 0;
  padding: 0;
  font-size: ${(props) => props.theme.fontSizes.medium} !important;
`

export const PricingGridTable = styled.table`
  width: 100%;

  th,
  td {
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 20px 10px;
  }

  h2 {
    font-size: ${(props) => props.theme.fontSizes.medium};
    padding: 0px;
  }
`

export const StickyPricingGrid = styled(PricingGridTable)`
  position: sticky;
  top: var(--chalice-navbar-height);
  background-color: ${(props) => props.theme.colors.white};
`

export const PgTierRow = styled.tr`
  position: sticky;
  top: 80px;

  td {
    padding: 0 !important;
  }
`
